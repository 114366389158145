<template>
  <a-modal title="物流详情" width="50%" v-model="visible" :footer="null" :maskClosable="false">
    <a-row>
      <a-col :span="18">
        <a-timeline v-if="dataList" v-for="(row, index) in dataList">
          <a-timeline-item>{{ row.acceptStation }} {{ row.acceptTime }}</a-timeline-item>
        </a-timeline>
        <a-timeline v-else>
          <a-timeline-item>已下单 2015-09-01</a-timeline-item>
          <a-timeline-item>已出库 2015-09-01</a-timeline-item>
          <a-timeline-item>已发货 2015-09-01</a-timeline-item>
          <a-timeline-item>已揽件 2015-09-01</a-timeline-item>
          <a-timeline-item>运输中 2015-09-01</a-timeline-item>
          <a-timeline-item>派送中 2015-09-01</a-timeline-item>
          <a-timeline-item>待取件 2015-09-01</a-timeline-item>
          <a-timeline-item>待派送 2015-09-01</a-timeline-item>
          <a-timeline-item>待取件 2015-09-01</a-timeline-item>
          <a-timeline-item>已签收 2015-09-01</a-timeline-item>
        </a-timeline>
      </a-col>
      <a-col :span="18">
        <a-button type="primary" @click="handlerUpDateStatus('UpDate')">更新快递状态</a-button>
      </a-col>
    </a-row>
    <div class="footer-btns" v-if="handle != 'check'">
      <a-button type="default" @click="visible = false">取消</a-button>
      <a-button type="primary" @click="toSubmit">提交</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      dataList: [],
      deliveryinfo: {}
    }
  },
  mounted() {},
  methods: {
    setRowData(row) {
      this.visible = true
      this.rowData = row
      const obj = {
        customerName: row.consigneePhone.substr(row.consigneePhone.length-4) ,
        logisticCode: row.expressCode,
        shipperCode: row.expressCompanyCode
      }
      this.axios.post(`/api/order/logistics/logisticsInfo/queryDeiveryExpressList`, obj).then((res) => {
        this.dataList = res.body.Traces
        this.deliveryinfo = res.body
        console.log('setRowData----',this.dataList,this.deliveryinfo)
        if (res.body.State) {
          this.updateLogisticsState(res.body.State);
        }
      })
    },
    handlerUpDateStatus(type) {
      console.log(type);
      const obj = {
        customerName: this.rowData.consigneePhone.substr(this.rowData.consigneePhone.length-4) ,
        logisticCode: this.rowData.expressCode,
        shipperCode: this.rowData.expressCompanyCode
      }
      this.axios.post(`/api/order/logistics/logisticsInfo/queryDeiveryExpressList`, obj).then((res) => {
        this.dataList = res.body.Traces
        this.deliveryinfo = res.body
        console.log('handlerUpDateStatus----',this.dataList,this.deliveryinfo)
        if (res.body.State) {
          this.updateLogisticsState(res.body.State);
        }
      })
    },
    updateLogisticsState(state){
      const obj = {
        expressStatus: state,
        id: this.rowData.id
      }
      this.axios.post(`/api/order/invoice/orderShipInfo/invoiceAdmin/updateLogisticsState`, obj).then((res) => {
        console.log('updateLogisticsState----',res)
      })
    },
    toSubmit() {

    }
  },
}
</script>

<style lang="less" scoped>
.footer-btns {
  text-align: right;
}
</style>
