<template>
  <a-spin :spinning="spinning">
    <a-card :border="false">
      <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="快递状态">
                <DictSelect
                  field="expressStatus"
                  :value.sync="searchData.expressStatus"
                  style="width: 100%"
                  placeholder="请选择快递状态"
                ></DictSelect>
              </a-form-model-item>
            </a-col>
<!--            <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--              <a-form-model-item label="快递公司">-->
<!--                <a-select placeholder="快递公司">-->
<!--                  <a-select-option v-for="expressItem in express" :key="expressItem">-->
<!--                    {{ expressItem }}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-model-item>-->
<!--            </a-col>-->
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="快递公司">
                <a-input
                  v-model="searchData.expressCompany"
                  placeholder="请输入快递公司"
                  allowClear
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="快递单号">
                <a-input
                  v-model="searchData.expressCode"
                  placeholder="请输入快递单号"
                  allowClear
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="发票号">
                <a-input v-model="searchData.invoiceCode" placeholder="请输入发票号" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                <a-button type="default" @click="reset()" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div>
          <a-button v-if="isAdd" type="primary" @click="hanlderTest('add')" icon="plus">新增</a-button>
          <a-button v-if="selectedRowKeys.length >= 1 && isEdit" type="primary" @click="hanlderTest('edit')" icon="plus">编辑</a-button>
          <a-button v-if="selectedRowKeys.length >= 1 && isCheck" type="primary" @click="hanlderTest('check')" icon="copy">查看</a-button>
          <downLoad
            method="get"
            api="/api/order/order/orderInfo/exportSingleOrderInfo"
            :params="{ id: 11 }"
            name="导出.xlsx"
            >导入</downLoad
          >
        </div>
        <!-- 数据表格 -->
        <a-table
          :loading="tableLoading"
          :row-selection="rowSelection"
          :rowKey="(record) => record.id"
          :columns="columns"
          :data-source="tableData"
          bordered
          @change="changeTable"
          :customRow="changeTableRow"
          :pagination="false"
        >
<!--          <span slot="KDGSStatus" slot-scope="text">-->
<!--            <span v-if="text == 1">邮政</span>-->
<!--            <span v-if="text == 2">韵达</span>-->
<!--            <span v-if="text == 3">顺丰</span>-->
<!--            <span v-if="text == 4">中通</span>-->
<!--          </span>-->
<!--          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>-->
          <span slot="expressStatus" slot-scope="text">
            <a-tag color="#4bacc6" v-if="text == 0">待寄件</a-tag>
            <a-tag color="#4bacc6" v-if="text == 1">已揽件</a-tag>
            <a-tag color="#e6a23c" v-if="text == 2">在途中</a-tag>
            <a-tag color="#67c23a" v-if="text == 3">已签收</a-tag>
            <a-tag color="#ff7875" v-if="text == 4">问题件</a-tag>
          </span>
          <div slot="action" slot-scope="text">
            <a-button type="dashed"> 查看 </a-button>
            <a-button type="dashed"> 修改 </a-button>
            <a-button type="danger"> 删除 </a-button>
          </div>
        </a-table>
        <InvoiceCreditModal ref="InvoiceCreditModal" @reload="getData"></InvoiceCreditModal>
        <InvoiceInfoModal ref="InvoiceInfoModal"></InvoiceInfoModal>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import { columns } from './components/colums.js'
import InvoiceCreditModal from './components/InvoiceCreditModal.vue'
import InvoiceInfoModal from './components/InvoiceInfoModal.vue'
import {listInvoiceAdmin} from './api/FinanceReceiptApi'
import { auditTaskBatch } from '@/api/activiti'
import { PAYMENT_TEMP } from '@/utils/downloadTempHref'
import {checkPermission} from "@/utils/permissions";
export default {
  name: 'financeReceipt',
  components: {
    InvoiceCreditModal,
    InvoiceInfoModal,
  },
  data() {
    return {
      columns: columns,
      express: ['邮政', '韵达'],
      expressStatus: ['已发货', '已揽件', '配送中', '已签收'],
      tableData: [],
      searchData: {}, // 搜索条件
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      confirmLoading: false,
      visible: false,
      visibleAll: false,
      loading: false,
      loadingAll: false,
      rejectReason: '',
      verifyRemark: '',
      dealerList: [],
      acceptanceList: [],
      dhTypeSourceList: [],
      regionalOffice: [],
      downloadTempHref: PAYMENT_TEMP,
      spinning: false,
      isAdd: checkPermission('invoice:logistics:add'),
      isEdit: checkPermission('invoice:logistics:edit'),
      isCheck: checkPermission('invoice:logistics:check'),
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    getToken() {
      return this.$sotre.state.SET_TOKEN()
    },
  },

  methods: {
    hanlderTest(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.InvoiceCreditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0 || _this.selectedRows.length > 1) {
        return _this.$message.warning('请选择一条记录')
      }
      if (name === 'edit') {
        _this.$refs.InvoiceCreditModal.setRowData(_this.selectedRows[0], 'edit')
        return
      }
      if (name === 'check') {
        _this.$refs.InvoiceInfoModal.setRowData(_this.selectedRows[0], 'check')
        return
      }
    },
    /**
     *
     *
     *
     *
     *
     */
    downLoadTemp() {
      window.open(this.downloadTempHref, '_blank')
    },
    startDownLoad() {
      this.spinning = true
    },
    downLoadDone() {
      this.spinning = false
    },
    //导入
    uploadChange(info) {
      this.tableLoading = true
      setTimeout(() => {
        if (info.file.response) {
          let hint = info.file.response
          if (hint.code === 200) {
            this.tableLoading = false
            this.$message.success(hint.message)
            this.getData()
          } else if (hint.code === 500) {
            this.tableLoading = false
            this.$message.error(hint.message)
          }
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList)
          }
        }
      })
    },
    // 批量审核弹框
    toHandlerAll() {
      let everyResult = this.selectedRows.every((item) => {
        return item.auditStatus === 2 && item.taskId != null
      })
      if (everyResult) {
        this.visibleAll = true
        this.dataList = this.selectedRows
      } else {
        this.$notification.error({ message: '请选择相同待审核记录' })
      }
    },

    /**
     * 批量提交审核
     */
    batchSubmitAudit() {
      var _this = this
      const everyResult = this.selectedRows.every((item) => {
        return item.auditStatus === 1
      })
      if (everyResult) {
        this.dataList = this.selectedRows
        _this.$confirm({
          title: '提交审核',
          content: '确认要提交审核吗?',
          okText: '确认',
          cancelText: '取消',
          async onOk() {
            _this.tableLoading = true
            const query = {
              ids: _this.dataList.map((item) => item.id),
            }
            _this.axios.post('/api/dealer/dealer/dealerPaymentFrom/batchSubmitAudit', query).then((res) => {
              if (res.code == 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
                _this.getData()
              }
            })
          },
          onCancel() {},
        })
      } else {
        this.$notification.error({ message: '请选择未提交审核记录' })
      }
    },
    onAuditAll(name) {
      const _this = this
      _this.loadingAll = true
      let result = 2
      if (name == 'refuse') {
        result = 3
      }
      auditTaskBatch({
        taskIds: _this.dataList.map((item) => item.taskId),
        result: result,
        comment: _this.verifyRemark,
      }).then((res) => {
        if (res.code == 200) {
          setTimeout(() => {
            _this.$message.success('审核成功')
            _this.getData()
            _this.verifyRemark = ''
            _this.visibleAll = false
            _this.loadingAll = false
          }, 3000)
        } else {
          _this.$message.error(res.message)
          _this.getData()
          _this.verifyRemark = ''
          _this.visibleAll = false
          _this.loadingAll = false
        }
      })
    },

    handleCanceAll() {
      this.visibleAll = false
      this.verifyRemark = ''
      this.loadingAll = false
    },
    downItemExcel() {
      const _this = this
      const params = _this.tableData
      downExcel(params)
        .then((res) => {
          console.log('进不来')
        })
        .catch((error) => {
          const blob = new Blob([error], {
            type: 'application/vnd.ms-excel',
          })
          const objectUrl = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = objectUrl
          a.download = '收款单'
          //a.click();
          //下面这个写法兼容火狐
          a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
          window.URL.revokeObjectURL(blob)
        })
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listInvoiceAdmin({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
            // this.tableData = [
            //   {
            //     FPH: '1334364467884',
            //     KDGS: 1,
            //     KDDH: '1188992234555',
            //     KDZT: 1,
            //   },
            //   {
            //     FPH: '1334364467884',
            //     KDGS: 2,
            //     KDDH: '1188992234555',
            //     KDZT: 2,
            //   },
            //   {
            //     FPH: '1334364467884',
            //     KDGS: 3,
            //     KDDH: '1188992234555',
            //     KDZT: 3,
            //   },
            //   {
            //     FPH: '1334364467884',
            //     KDGS: 4,
            //     KDDH: '1188992234555',
            //     KDZT: 4,
            //   },
            // ]
          }
        })
        .finally(() => (this.tableLoading = false))

      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        // 获取大区事务所经销商数据
        this.dealerList = res.body
      })
      this.axios.get('/api/base/system/dictionary/selectByCode/' + '兑换时长').then((res) => {
        // 获取字典电汇类型来源
        this.acceptanceList = res.body
      })

      this.axios.get('/api/base/system/dictionary/selectByCode/' + '电汇类型来源').then((res) => {
        // 获取字典兑换时长
        this.dhTypeSourceList = res.body
      })
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },

    // 审核
    onAudit(name) {
      const _this = this
      _this.loading = true
      let query = {
        id: _this.selectedRows[0].id,
        auditStatus: name == 'audit' ? 3 : 4,
        reviewNotes: _this.rejectReason,
        dealerId: _this.selectedRows[0].dealerId,
      }
      _this.axios.post('/api/dealer/dealer/dealerPaymentFrom/toExamine', query).then((res) => {
        if (res.code == 200) {
          _this.$notification.success({ message: res.message })
          _this.getData()
          _this.rejectReason = ''
          _this.visible = false
        } else {
          _this.$notification.error({ message: res.message })
          _this.getData()
        }
      })
      setTimeout(() => {
        _this.visible = false
        _this.loading = false
      }, 3000)
    },

    // 查看
    onCheck(item) {
      const _this = this
      _this.$refs.FinanceReceiptInfoModal.setRowData(item, 'check')
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.loadingAll = false
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.FinanceReceiptEditModal.setRowData({}, 'add')
        return
      }

      if (_this.selectedRows.length === 0 || _this.selectedRows.length > 1) {
        return _this.$message.warning('请选择一条记录')
      }
      switch (name) {
        case 'edit':
          _this.$refs.FinanceReceiptEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'export':
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/api/customer/customer/financeReceipt/excel=${this.selectedRowKeys[0]}`
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delFinanceReceipt(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.FinanceReceiptInfoModal.setRowData(_this.selectedRows[0], 'check')
          break
        case 'rollbackStatus':
          _this.$confirm({
            title: '撤审警告',
            content: '确认要撤回此条记录的审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await rollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'returnStatus':
          _this.$confirm({
            title: '返审警告',
            content: '确认要将此条记录返审至草稿状态吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await returnStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
