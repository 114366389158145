/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-01-21 16:31:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-07-05 13:42:24
 */
export const columns = [
  {
    title: '发票号',
    dataIndex: 'invoiceCode',
    key: 'invoiceCode',
    align: 'center',
  },
  {
    title: '快递单号',
    dataIndex: 'expressCode',
    key: 'expressCode',
    align: 'center',
  },
  {
    title: '快递状态',
    dataIndex: 'expressStatus',
    key: 'expressStatus',
    align: 'center',
    scopedSlots: { customRender: 'expressStatus' }
  },
  {
    title: '快递公司',
    dataIndex: 'expressCompany',
    key: 'expressCompany',
    align: 'center',
  },
  // {
  //   title: '操作',
  //   key: 'operation',
  //   fixed: 'right',
  //   align: 'center',
  //   width: 300,
  //   scopedSlots: { customRender: 'action' },
  // },
]
