<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="600"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-model-item label="快递公司" prop="expressCompanyId" >
        <a-select placeholder="请选择快递公司" v-model="rowData.expressCompanyId">
          <a-select-option :value="item.id" v-for="(item, index) in expressCompanyList" :key="index">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="快递单号" prop="expressCode">
        <a-input v-model="rowData.expressCode"></a-input>
      </a-form-model-item>
      <a-form-model-item label="发票号" prop="invoiceCode">
        <a-textarea v-model="rowData.invoiceCode" placeholder="填写多个时请用,隔开" :auto-size="{ minRows: 3, maxRows: 5 }" />
      </a-form-model-item>
      <a-form-model-item label="收货人" prop="consignee">
        <a-input v-model="rowData.consignee"></a-input>
      </a-form-model-item>
      <a-form-model-item label="收货人电话" prop="consigneePhone">
        <a-input v-model="rowData.consigneePhone"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {addInvoiceLogistics, editInvoiceLogistics, selectByIdInvoiceLogistics} from '../api/FinanceReceiptApi'

export default {
  components: {
  },

  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      expressCompanyList:[],
      // 表单验证
      formRule: {
        expressCompanyId: [{ required: true, message: '请选择快递公司', trigger: 'change' }],
        expressCode: [{ required: true, message: '请输入快递单号', trigger: 'blur' }],
        invoiceCode: [{ required: true, message: '请填写发票号', trigger: 'blur' }],
        consignee: [{ required: true, message: '请填写收货人', trigger: 'blur' }],
        consigneePhone: [
          { required: true, pattern: new RegExp(/^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16(2|[5-7]))|(17[0-8])|(18[0-9])|(19([0-3]|[5-9])))\d{8}$/), message: '请正确输入收货人电话', trigger: 'blur' }
        ],
      },
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      if (handle == 'edit') {
        selectByIdInvoiceLogistics(row.id).then(res => {
          this.rowData = res.body
        })
      }
      const params = {
        pageSize:1000
      }
      this.axios.get('/api/order/logistics/logisticsCompany/list',params).then((res) => {
        if (res.code == 200) {
          this.expressCompanyList = res.body.records
        }
      })
    },

    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.rowData.invoiceCode.replace('，', ',')
        const res =
          this.handle === 'add' ? await addInvoiceLogistics(this.rowData) : await editInvoiceLogistics(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
</style>
