/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-28 21:53:44
 * @LastEditors: hutian
 * @LastEditTime: 2021-04-20 10:39:22
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listInvoiceAdmin = params => axios({
    url: '/api/order/invoice/orderShipInfo/invoiceAdmin/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdInvoiceLogistics = params => axios({
  url: '/api/order/invoice/orderShipInfo/invoiceAdmin/selectByIdInvoiceLogistics/' + params,
  method: 'get',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const addInvoiceLogistics = params => axios({
  url: '/api/order/invoice/orderShipInfo/invoiceAdmin/addInvoiceLogistics',
  method: 'post',
  data: JSON.stringify(params),
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

export const editInvoiceLogistics = params => axios({
  url: '/api/order/invoice/orderShipInfo/invoiceAdmin/editInvoiceLogistics',
  method: 'post',
  data: JSON.stringify(params),
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

export const selectExpressCodeByInvoiceCode = params => axios({
  url: '/api/order/invoice/orderShipInfo/invoiceAdmin/selectExpressCodeByInvoiceCode/' + params,
  method: 'get',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})




